import { gql, useMutation, MutationHookOptions } from '@apollo/client';

import { Mutation, MutationCreateContactArgs } from '../../generated/types';

export const CREATE_CONTACT = gql`
  mutation CreateContact(
    $entityType: EntityType!
    $userUuid: String!
    $boardUuid: String
    $jobUuid: String!
    $data: ContactInputData!
  ) {
    createContact(entityType: $entityType, userUuid: $userUuid, boardUuid: $boardUuid, jobUuid: $jobUuid, data: $data) {
      uuid
      email
      fullName
      linkedin
      phoneNumber
      position
      avatar
      isDeleted
      createdAt
      updatedAt
      createdBy {
        uuid
        email
        nickname
        name
      }
      updatedBy {
        uuid
        email
        nickname
        name
      }
    }
  }
`;

export const useCreateContact = (
  options?: MutationHookOptions<Pick<Mutation, 'createContact'>, MutationCreateContactArgs>
) =>
  useMutation<Pick<Mutation, 'createContact'>, MutationCreateContactArgs>(CREATE_CONTACT, {
    ...options,
  });
